import { SEO } from "components/core/seo"
import { NextPage } from "next"
import React from "react"
import { HomePage } from "components/homepage"
import { dehydrate, QueryClient } from "react-query"
import { getPublicWidgetsKey } from "reactQuery/widget"
import { selectPublicWidgets } from "repository/widget"
import { BASE_URL } from "lib/utils"

export const IndexPage: NextPage = () => (
  <>
    <SEO
      title="Utilities for your recurring Frontend tasks"
      description="Frontend Toolkit offers customizable dashboards for your recurring Frontend tasks. Base64 encoder/decoder, SVG optimizations, SVG to JSX and many more!"
      ogImage={{
        title: "Customizable dashboards",
        subTitle: "Utilities for your recurring Frontend tasks",
        url: `${BASE_URL}`,
      }}
    />
    <HomePage />
  </>
)

export const getStaticProps = async () => {
  const queryClient = new QueryClient()

  await queryClient.prefetchQuery(getPublicWidgetsKey(), selectPublicWidgets)

  return {
    props: {
      dehydratedState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
    },
  }
}

export default IndexPage
